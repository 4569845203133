<template>
  <div class="delete-proforma-frais">
    <v-tooltip
      bottom
      color="#311B92"
      v-if="permission && computedCheckedRowsProformat.length"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop="handleShowConfirmAlert"
        >
          <font-awesome-icon icon="trash" />
        </div>
      </template>
      <span>Supprimer Proforma</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    updateFunction: { required: true },
    permission: {
      required: true
    }
  },
  data() {
    return {
      error: null,
      loading: false
    };
  },
  methods: {
    resetModal() {
      this.error = null;
      this.loading = false;
    },
    handleShowConfirmAlert() {
      let htmlMessage =
        "<div  style='margin: auto;width: 50%;height: 200px;overflow-x: auto;' class='ModalScrol'><div> ";
      for (let j = 0; j < this.computedCheckedRowsProformat.length; j++) {
        htmlMessage =
          htmlMessage +
          '<li>' +
          this.computedCheckedRowsProformat[j].num +
          '</li>';
      }
      htmlMessage = htmlMessage + '</div></div>';
      this.$swal
        .fire({
          background: 'rgb(245 245 252)',
          title: 'Êtes-vous sur de supprimer les proformats suivantes',
          type: 'warning',
          icon: 'warning',
          locale: 'fr',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          showCloseButton: true,
          showConfirmButton: true,
          showCancelButton: true,
          html: htmlMessage,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonClass: 'btn btn-success',
          cancelButtonClass: 'btn btn-danger',
          cancelButtonColor: '#d33',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            for (let i = 0; i < this.computedCheckedRowsProformat.length; i++) {
              bodyFormData.append(
                'ids[' + [i] + ']',
                this.computedCheckedRowsProformat[i].id
              );
            }
            const response = await this.updateFunction({
              ids: bodyFormData,
              data: this.computedCheckedRowsProformat
            });
            if (response.succes) {
              this.$alert('', 'Proforma supprimé avec succès', 'success');
              this.$emit('refreshData');
            }
          }
        });
    }
  },
  computed: {
    ...mapGetters(['checkPermission']),
    computedCheckedRowsProformat() {
      return this.dataToUse
        .map(({ projects }) => projects)
        .flat()
        .filter(f => f.check == true && f.type_facture == 'Facture proforma');
    }
  }
};
</script>

<style scoped lang="scss">
.button-export-style {
  background-color: #e4261b;
  &:hover,
  &:focus {
    background-color: #e4261b;
  }
}
</style>
<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-validation-statut-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
.ModalScrol::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #704ad1;
  border-radius: 10px;
}
.ModalScrol::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
<style lang="scss">
button.swal2-confirm.order-1.btn.btn-success.swal2-styled {
  background: #704ad1;
  border: none !important;
}
.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px #704ad1 !important;
}
button.swal2-cancel.order-2.btn.swal2-styled.swal2-default-outline,
button.swal2-cancel.order-2.btn.swal2-styled.swal2-default-outline:hover,
button.swal2-cancel.order-2.btn.swal2-styled.swal2-default-outline:focus {
  border: 1px solid;
  background-color: #fff !important;
  color: #704ad1 !important;
  border-color: #704ad1 !important;
}
h2#swal2-title {
  font-family: 'Montserrat';
}
.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show {
  width: 40%;
  border-radius: 10px !important;
}
</style>
